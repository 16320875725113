<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">
                  Liquidaciónes Internas {{ form ? form.id : "" }}
                </h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/cs">Carga Seca</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Formulario {{ accion }} Liquidaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        placeholder="Nombre"
                        v-model="form.nombre"
                        :disabled="estadodis"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="bloque">Bloque</label>
                      <v-select
                        :class="[
                          $v.form.bloque_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.bloques"
                        placeholder="Bloque"
                        label="nombre"
                        :options="listasForms.bloques"
                        @search="buscarBloque"
                        @input="selectBloque()"
                        multiple
                        :disabled="estadodis"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="empresa">Empresa</label>
                      <v-select
                        :class="[
                          $v.form.empresa_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="empresa"
                        placeholder="Seleccione Empresa..."
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="obs">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm"
                        id="obs"
                        cols="20"
                        rows="2"
                        v-model="form.observaciones"
                        :disabled="estadodis"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_ini">Fecha Inicio</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_ini"
                        :class="
                          $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_fin">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_fin"
                        :class="
                          $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="estado">Estado</label>
                      <select
                        id="estado"
                        v-model="form.estado"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          :disabled="
                            estado.numeracion === 2 &&
                            $route.params.accion == 'Crear'
                          "
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Seleccione para Liquidar</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <label for="tipo_servicio">Tipo Servicio</label>
                    <v-select
                      id="tipo_servicio"
                      v-model="form.tipo_servicio"
                      :class="[
                        $v.form.tipo_servicio.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      placeholder="Tipo Solicitud"
                      label="descripcion"
                      :options="listasForms.tipos_servicios"
                      @input="selectTipoServicio()"
                      :disabled="
                        form.fecha_ini == '' ||
                        form.fecha_fin == '' ||
                        estadodis
                      "
                      multiple
                    >
                    </v-select>
                  </div>
                  <div class="col-md-11 text-center m-2">
                    <h6 v-if="form.fecha_ini == '' || form.fecha_fin == ''">
                      <span class="badge badge-warning"
                        ><i class="fas fa-times-circle"></i> Recuerde que se
                        habilita el campo cuando esten llenas las fechas</span
                      >
                    </h6>
                  </div>
                  <div class="col-md-2"></div>
                </div>
                <div class="row">
                  <div
                    class="col-md-6"
                    v-if="
                      form.tipo_servicio.some((serv) => serv.numeracion == 1)
                    "
                  >
                    <div class="form-group">
                      <label for="tipo_vehiculo">Tipo Vehículo</label>
                      <div class="row">
                        <div class="col-md-10">
                          <v-select
                            id="tipo_vehiculo"
                            v-model="form.tipo_vehiculo"
                            :class="[
                              $v.form.tipo_vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            placeholder="Tipo Vehículo"
                            label="nombre"
                            :options="listasForms.tipos_vehiculos"
                            @input="selectTipoVeh()"
                            multiple
                            :disabled="estadodis"
                          >
                          </v-select>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="
                            listasForms.tipos_vehiculos.length !=
                            form.tipo_vehiculo.length
                          "
                        >
                          <button
                            class="btn alert-default-info"
                            @click="seleccionarVehiculos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-list"></i>
                          </button>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="form.tipo_vehiculo.length > 0"
                        >
                          <button
                            class="btn alert-default-danger"
                            @click="limpiarVehiculos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="
                      form.tipo_servicio.some((serv) => serv.numeracion == 2)
                    "
                  >
                    <div class="form-group">
                      <label for="tipo_equipo">Tipo Equipo</label>
                      <div class="row">
                        <div class="col-md-10">
                          <v-select
                            :class="[
                              $v.form.tipo_equipo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.tipo_equipo"
                            placeholder="Tipo Equipo"
                            label="descripcion"
                            :options="listasForms.tipos_equipos"
                            multiple
                            @input="selectTipoEquipo()"
                            :disabled="estadodis"
                          >
                          </v-select>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="
                            listasForms.tipos_equipos.length !=
                            form.tipo_equipo.length
                          "
                        >
                          <button
                            class="btn alert-default-info"
                            @click="seleccionarEquipos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-list"></i>
                          </button>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="form.tipo_equipo.length > 0"
                        >
                          <button
                            class="btn alert-default-danger"
                            @click="limpiarEquipos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer mt-1">
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <button
                      class="btn bg-primary col-md-4"
                      v-if="
                        !$v.form.$invalid &&
                        $store.getters.can('cs.liquidacionesInternos.create')
                      "
                      @click="save()"
                      :disabled="estadodis"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                    <button class="btn bg-secondary col-md-4" @click="back()">
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                    <button
                      v-if="liquidacion && liquidacion.id"
                      class="btn bg-indigo col-md-4"
                      @click="printTicket(liquidacion)"
                    >
                      <i class="fas fa-print"></i><br />Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card"
              v-if="
                liquidacion &&
                liquidacion.cs_liquidaciones_internas_firmas &&
                liquidacion.cs_liquidaciones_internas_firmas.length > 0
              "
            >
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Aprobaciones</h5>
              </div>
              <div class="card-body">
                <div class="row justify-content-center">
                  <div
                    class="col-md-4"
                    v-for="firma in liquidacion.cs_liquidaciones_internas_firmas"
                    :key="firma.id"
                  >
                    <div class="card bg-light d-flex flex-fill">
                      <div class="card-header text-muted border-bottom-0">
                        <h5>
                          {{
                            firma.tipo_firma == 1
                              ? "Firma Contratista"
                              : firma.tipo_firma == 2
                              ? "Firma Interventor del Contrato"
                              : firma.tipo_firma == 3
                              ? "Firma Administrador del Contrato"
                              : ""
                          }}
                        </h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <p>
                              Firmado por: <br /><strong>{{
                                firma.user.name
                              }}</strong>
                            </p>
                            <p class="text-center mr-5 ml-5">
                              <img
                                :src="uri_docs + firma.user.link_firma"
                                class="img-fluid"
                              />
                            </p>
                            <p>
                              Fecha: <br /><strong>{{
                                firma.fecha_firma
                              }}</strong>
                            </p>
                            <p>
                              Observación: <br />
                              {{ firma.observacion }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" v-if="form.id">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Resumen</h5>
              </div>
              <div class="card-body p-2">
                <div id="accordion" v-for="det in detLiquidacion" :key="det.id">
                  <CsResumenServicio
                    ref="CsResumenServicio"
                    :det="det"
                  ></CsResumenServicio>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Centros costo</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <vue-pivottable-ui
                      :data="pivotData"
                      :aggregatorName="aggregatorName"
                      renderer-name="Table Heatmap"
                      :row-total="true"
                      :col-total="true"
                      :rows="['equipo']"
                      :vals="['valor_base_porc']"
                      :cols="['codigo_contable']"
                      ref="pvt"
                    >
                    </vue-pivottable-ui>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { VuePivottableUi } from "vue-pivottable";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import "vue-pivottable/dist/vue-pivottable.css";
import CsResumenServicio from "./CsResumenServicio.vue";

export default {
  name: "CsLiquidacionInternosForm",
  components: {
    vSelect,
    VuePivottableUi,
    Loading,
    CsResumenServicio,
  },

  data() {
    return {
      id: null,
      accion: "",
      metodo: "",
      firma_admin: 0,
      cargando: false,
      estadodis: false,
      bloque: {},
      empresa: {},
      detLiquidacion: {},
      resumen: {},
      tarifaLiqui: {},
      cantTipos: {},
      liquidacion: {},
      indice_tabla: 0,
      form: {
        nombre: null,
        bloques: [],
        bloque_id: [],
        empresa_id: null,
        fecha_ini: "",
        fecha_fin: "",
        estado: null,
        tipo_servicio: [],
        tipo_servicio_id: [],
        tipo_vehiculo: [],
        tipo_vehiculo_id: [],
        tipo_equipo: [],
        tipo_equipo_id: [],
      },
      buscarResum: {
        servicio: "",
        vhEqu: "",
        condApa: "",
        fechaIni: "",
        fechaFin: "",
        turno: "",
      },
      listasForms: {
        bloques: [],
        empresas: [],
        estados: [],
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
      },
      form1: {},
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let tv = {
      required: false,
    };
    let te = {
      required: false,
    };

    if (this.form.tipo_servicio.find((t) => t.numeracion == 1)) {
      tv = {
        required,
      };
    }
    if (this.form.tipo_servicio.find((t) => t.numeracion == 2)) {
      te = {
        required,
      };
    }
    return {
      form: {
        nombre: {
          required,
        },
        bloque_id: {
          required,
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        empresa_id: {
          required,
        },
        estado: {
          required,
        },
        tipo_servicio: {
          required,
        },
        tipo_vehiculo: tv,
        tipo_equipo: te,
      },
    };
  },

  created() {
    this.seleccionesPrevias = [...this.form.tipo_servicio]; // Inicializa las selecciones previas
  },

  methods: {
    async init() {
      this.cargando = true;
      this.liquidacion = this.$route.params.data_edit;
      await this.buscarEmpresas();
      await this.getEstados();
      await this.getTipoSolicitud();

      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;

      if (this.$route.params.accion == "Editar") {
        this.estadodis =
          this.liquidacion.estado == 2
            ? true
            : !(await this.$store.getters.can("cs.liquidacionesInternos.edit"));

        this.form.nombre = this.liquidacion.nombre;
        this.form.empresa_id = this.liquidacion.empresa_id;
        this.form.observaciones = this.liquidacion.observaciones;
        this.form.fecha_ini = this.liquidacion.fecha_ini;
        this.form.fecha_fin = this.liquidacion.fecha_fin;
        this.form.estado = this.liquidacion.estado;
        this.form.id = this.liquidacion.id;
        this.getResumenCC(this.form.id);
        this.form.bloques = this.liquidacion.bloques;
        let tipoServ = this.liquidacion.cs_liquidaciones_internas_tipo_serv;
        if (tipoServ) {
          let tipServSelect = [];
          await tipoServ.forEach(async (t) => {
            let find_servTip = this.listasForms.tipos_servicios.find(
              (l) => l.numeracion === t.tipo_servicio
            );

            if (find_servTip !== undefined) {
              let find_servTip2 = tipServSelect.find(
                (serv) => serv.numeracion === t.tipo_servicio
              );
              if (find_servTip2 === undefined) {
                tipServSelect.push(find_servTip);
              }
            }
          });
          this.form.tipo_servicio = tipServSelect;
          await this.selectTipoServicio();
        }

        let find_emp = this.listasForms.empresas.find(
          (e) => e.id === this.form.empresa_id
        );
        this.empresa = find_emp;
        this.form.bloque_id = this.form.bloques.map((bloque) => bloque.id);

        await this.liquidacion.cs_liquidaciones_internas_firmas.forEach(
          async (firma) => {
            if (firma.tipo_firma == 3) {
              this.firma_admin = 1;
              // await this.showFacturacion();
            }
          }
        );
        this.metodo = "PUT";
        this.getDetLiquidacion();
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.cargando = false;
      }
      this.cargando = false;
    },

    async getDetLiquidacion() {
      await axios
        .get("/api/cs/liquidacionesInternos/detLiquidacion", {
          params: {
            bloque_id: this.form.bloque_id,
            cs_liquidacion_interna_id: this.form.id,
            empresa_id: this.form.empresa_id,
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
          },
        })
        .then(async (response) => {
          this.detLiquidacion = response.data.detLiquidacion;
          this.resumen = response.data.resumen;
          this.cantTipos = response.data.servTotal;
          this.tarifaLiqui = response.data.tarifaLiqui;
        });
    },

    buscarBloque(search) {
      if (search != "") {
        var url = "api/admin/bloques/lista?bloque_id=" + search;
        axios
          .get(url)
          .then((response) => {
            this.listasForms.bloques = response.data;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectBloque() {
      this.form.bloque_id = this.form.bloques.map((e) => e.id);
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async selectTipoServicio() {
      this.form.tipo_vehiculo = [];
      this.form.tipo_servicio_id = this.form.tipo_servicio.map(
        (e) => e.numeracion
      );

      /* Agregar los tipos de servicios */
      if (this.form.tipo_servicio.length > 0) {
        if (this.form.tipo_servicio.some((serv) => serv.numeracion == 1)) {
          await this.getTipoVehiculos(1);
          if (this.liquidacion) {
            await this.liquidacion.cs_liquidaciones_internas_tipo_serv.forEach(
              async (t) => {
                if (t.tipo_servicio == 1) {
                  this.form.tipo_vehiculo.push(
                    this.listasForms.tipos_vehiculos.find(
                      (v) =>
                        v.tipo_vehi == t.vehiculo_equipo_id &&
                        v.id ==
                          (t.tipo_remolque != null
                            ? t.tipo_remolque
                            : t.vehiculo_equipo_id)
                    )
                  );
                }
              }
            );
          }
        }
        if (this.form.tipo_servicio.some((serv) => serv.numeracion == 2)) {
          await this.getTipoEquipo(2);
          await this.liquidacion.cs_liquidaciones_internas_tipo_serv.forEach(
            async (t) => {
              if (t.tipo_servicio == 2) {
                this.form.tipo_equipo.push(
                  this.listasForms.tipos_equipos.find(
                    (v) => v.numeracion == t.vehiculo_equipo_id
                  )
                );
              }
            }
          );
        }
      }

      /* Eliminar los tipos de servicios */
      const opcionesEliminadas = this.seleccionesPrevias.filter(
        (opcion) => !this.form.tipo_servicio.includes(opcion)
      );
      if (opcionesEliminadas.length > 0) {
        if (opcionesEliminadas.find((t) => t.numeracion == 1)) {
          this.form.tipo_vehiculo = [];
        }
        if (opcionesEliminadas.find((t) => t.numeracion == 2)) {
          this.form.tipo_equipo = [];
        }
      }
      this.seleccionesPrevias = [...this.form.tipo_servicio]; // Actualiza las selecciones previas
    },

    async getTipoVehiculos(tipo_servicio) {
      const url = "api/cs/liquidacionesInternos/tipoVhEqui";
      try {
        const response = await axios.get(url, {
          params: {
            linea_negocio_id: 4,
            tipo_servicio: tipo_servicio,
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
          },
        });
        let respuesta = response.data;
        this.listasForms.tipos_vehiculos = respuesta;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Ocurrió un error" + error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },

    selectTipoVeh() {
      this.form.tipo_vehiculo_id = this.form.tipo_vehiculo.map((e) => e.id);
    },

    selectTipoEquipo() {
      this.form.tipo_equipo_id = this.form.tipo_equipo.map((e) => e.id);
    },

    async getTipoEquipo(tipo_servicio) {
      const url = "api/cs/liquidacionesInternos/tipoVhEqui";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
            tipo_servicio: tipo_servicio,
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
          },
        })
        .then((response) => {
          let respuesta = response.data;
          this.listasForms.tipos_equipos = respuesta;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },

    async seleccionarEquipos() {
      let lista_equipos = "";
      this.listasForms.tipos_equipos.forEach((tip) => {
        lista_equipos += `<div>${tip.descripcion}</div>`;
      });

      await this.$swal({
        title: "Selección Equipos.",
        html: lista_equipos,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Si, Agregar!",
      }).then((result) => {
        if (result.value) {
          this.form.tipo_equipo = this.listasForms.tipos_equipos;
          this.form.tipo_equipo_id = this.form.tipo_equipo.map(
            (e) => e.numeracion
          );
        }
      });
    },

    limpiarEquipos() {
      this.form.tipo_equipo = [];
    },

    async seleccionarVehiculos() {
      let lista_vehiculos = "";
      this.listasForms.tipos_vehiculos.forEach((tip) => {
        lista_vehiculos += `<div>${tip.nombre}</div>`;
      });

      await this.$swal({
        title: "Selección Vehículos.",
        html: lista_vehiculos,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Si, Agregar!",
      }).then((result) => {
        if (result.value) {
          this.form.tipo_vehiculo = this.listasForms.tipos_vehiculos;
          this.form.tipo_vehiculo_id = this.form.tipo_vehiculo.map((e) => e.id);
        }
      });
    },

    limpiarVehiculos() {
      this.form.tipo_vehiculo = [];
    },

    async buscarEmpresas() {
      await axios
        .get("api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    async getEstados() {
      await axios.get("/api/lista/100").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validarLiquidacion() {
      axios({
        method: "GET",
        url: "/api/cs/liquidacionesInternos/valLiquidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (this.validar == "si") {
          this.$swal({
            icon: "error",
            title: `Ya existe una liquidación con los mismos parametros, ..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.bloque = {};
          this.form.bloque_id = null;
          this.form.fecha_ini = null;
          this.form.fecha_fin = null;
        }
      });
    },

    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/cs/liquidacionesInternos",
          data: this.form,
        })
          .then(async (response) => {
            if (response.data.error !== undefined) {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: response.data.error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              this.$route.params.accion = "Editar";
              this.$route.params.data_edit = response.data;
              this.metodo = "PUT";
              this.init();
              this.$swal({
                icon: "success",
                title: "Se actualizó la liquidación correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Cs/LiquidacionesInternos");
    },

    getBackgroundClass(indiceResum) {
      return indiceResum % 2 === 0 ? "bg-dark" : "bg-light"; // Alterna clases de fondo en función del índice
    },

    async printTicket(ticket) {
      let flag = true;
      if (
        !ticket.fecha_radicacion &&
        ticket.estado == 2 &&
        ticket.estado_proceso == 3
      ) {
        await this.$swal({
          title: "Está seguro de generar el Ticket?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Generar!",
        }).then((result) => {
          if (!result.value) {
            flag = false;
          }
        });
      }

      if (flag) {
        this.cargando = true;
        const data = {
          ticket_id: ticket.id,
          fecha_radicacion: ticket.fecha_radicacion,
          estado: ticket.estado,
          estado_proceso: ticket.estado_proceso,
        };
        axios
          .get(`/api/cs/liquidacionesInternos/ticket`, {
            params: data,
          })
          .then((response) => {
            let data = response.data;
            this.cargando = false;
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    async getResumenCC(id) {
      const url = "api/cs/liquidacionesInternos/showCC";
      await axios
        .get(url, {
          params: {
            ticket_id: id,
          },
        })
        .then((response) => {
          this.pivotData = response.data;
          this.$refs.pvt.propsData.aggregatorName = "Sum";
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>
<style>
.bg-lightred {
  background-color: #ffcccc; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}

.bg-lightgreen {
  background-color: #e5f7e0; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}
.bg-lightyellow {
  background-color: #ffffcc; /* Color amarillo claro */
}
.header-resum {
  display: block;
  margin: 0px 0;
  padding: 2px;
  text-align: left;
}
.header-des {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.pvtTable {
  width: 100%;
}
</style>
